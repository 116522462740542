<template>
  <div id="misc">
    <div class="page-title text-center px-4">
      <h2 class="text--primary d-flex align-center justify-center">
        <span class="text-h4 font-weight-bold">Sayfa Bulunamadı</span>
      </h2>
      <p class="text-sm pt-1">Aradığınız Sayfayı Bulamadık</p>

      <v-btn color="primary" depressed to="/" class="mb-4"> Ana Sayfaya Dön </v-btn>
    </div>
  </div>
</template>

<script>
import { mdiAlert } from '@mdi/js'

export default {
  setup() {
    return {
      icons: {
        mdiAlert,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/misc.scss';
</style>
